import axios from "axios"

// Users API
export const verifyEMail = ({ username, useremail }) => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("useremail", useremail);

    return axios.post(`https://cryptostallionsnft.com/apis/device/sign_up`, formData,{
        headers:{
            "Content-Type": "multipart/form-data"
        }
    }).then((response) => {
        return response;
    });
}


